
import React, {useState, useEffect} from "react"

import Layout from "../../layout"
import WebappsForm from "../../webapps/form"


const formatTools = require("../../../../lib/formatTools");
const negosyonowAPI = require("../../../../lib/requestNegosyownow")
const webappsAPI = require("../../../../lib/requestWebapps");

const ENTITYID="onsbooking"

const nnpickupaliaspickerfields = [
	{"label":"Name", "dbfield": "nnpickupalias_name", "type": "text", "filtertype": "textbox"},
	{"label":"Address", "dbfield": "nnpickupalias_strtaddress", "type": "text", "filtertype": "textbox"}
];

const nnpickupaliaspickerfilter = [
	// Pick up
	{"field":"nnpickupalias_onsorderformfulfillmentid", "operation": "=", "value": 3}
];

const searchFields = [
	{"label":"Order Date", "dbfield": "onsorderform_datetime", "type": "datetime", "filtertype": "datetime"},
	{"label":"Order #", "dbfield": "onsorderform_code", "type": "text", "filtertype": "textbox"},
	{"label":"Seller", "dbfield": "onsbranch_name", "type": "text", "filtertype": "textbox"},
	{"label":"Fulfillment Method", "dbfield": "onsorderformfulfillment_name", "type": "text", "filtertype": "textbox"},
	{"label":"Location/Provider", "dbfield": "nnpickup_name", "type": "text", "filtertype": "textbox"},
	{"label":"Customer", "dbfield": "onscustomer_name", "type": "text", "filtertype": "textbox"},
	//{"label":"Amount", "dbfield": "onsbooking_totalamount", "type": "currency", "filtertype": "currency"},
	//{"label":"Order Status", "dbfield": "onsbookingstatus_name", "type": "text", "filtertype": "textbox"},

	{"label":"Package Status", "dbfield": "packagestatus_name", "type": "text", "filtertype": "textbox"},

	{"label":"Last Location", "dbfield": "nnpickupalias_name", "type": "text", "filtertype": "textbox"},
	{"label":"Package Updated", "dbfield": "onsbooking_locationdate", "type": "datetime", "filtertype": "datetime"}
];

const formFields = [
	[
		{"label":"Order Date", "field": "onsbooking_datetime", "value": "", "input": "datetime", "mode": "readonly"},
		{"label":"Order #", "field": "onsorderform_code", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"Seller", "field": "onsbranch_name", "value": "", "input": "picker", "mode": "readonly"},

		{"label":"Customer", "field": "onscustomer_name", "value": "", "input": "picker", "mode": "readonly"},

		{"label":"Last Updated", "field": "onsbooking_updated", "value": "", "input": "datetime", "mode": "readonly"},

		{"label":"Fulfillment Method", "field": "onsorderformfulfillment_name", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"Location/Provider", "field": "nnpickup_name", "value":"", "input": "picker", "mode": "readonly"},

		{"label":"Order Status", "field": "onsbookingstatus_name", "value":"", "input": "picker", "mode": "readonly"},

		{"label":"Package Status", "field": "packagestatus_id", "value": "", "input": "combo", "mode": "required", "options": [
			{"value":2,"display":"In-Transit"},
			{"value":3,"display":"Stored"},
			{"value":4,"display":"Disposed"},
			{"value":5,"display":"Released"},
			{"value":6,"display":"New"},
			{"value":1,"display":"Packed"},
		]},

		{"label":"New Location", "field": "nnpickupalias_name", "value":"", "input": "picker", "mode": "required", "pickerfields": nnpickupaliaspickerfields, "pickerfilters": nnpickupaliaspickerfilter},
		{"label":"Package Updated", "field": "onsbooking_locationdate", "value":"", "input": "datetime", "mode": "readonly"}

	]
];

const defaultSubformFields = [
	{
		"subformid": "packages",
		"label": "Packaging",
		"table": "onsbookingpackage",
		"sort": "onsbookingpackage_id",
		"mobilerowtitlefieldidx":[0],
		"minrow": 1,
		"maxrow": 1,
		"allowdel": false,
		"fieldlist": [
			{
				"label": "Package #",
				"field": "onsbookingpackage_packagenum",
				"subtotalid": "",
				"value": "",
				"input": "integer",
				"mode": "readonly"
			},
			{
				"label": "Description/Content",
				"field": "onsbookingpackage_name",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly"
			}
		],
		"footerlist": []
	},
	{
		"subformid": "onsbookinglocationhistory",
		"label": "History",
		"table": "onsbookinglocationhistory",
		"sort": "onsbookinglocationhistory_datetime desc",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 0,
		"allowdel": false,
		"fieldlist": [
			{
				"label": "Date/Time",
				"field": "onsbookinglocationhistory_datetime",
				"subtotalid": "",
				"value": "",
				"input": "datetime",
				"mode": "readonly"
			},
			{
				"label": "Location",
				"field": "nnpickup_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "readonly"
			},
			{
				"label": "Activity",
				"field": "onsbookinglocationhistory_action",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly"
			},
			{
				"label": "Encoder",
				"field": "onsbookinglocationhistory_user",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly"
			},
			{
				"label": "Notes",
				"field": "onsbookinglocationhistory_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly"
			}
		],
		"footerlist": []
	}
	/*,
	{
		"subformid": "item",
		"label": "Items",
		"table": "onsbookingitem",
		"sort": "onsbookingitem_id",
		"mobilerowtitlefieldidx":[0,4],
		"minrow": 0,
		"maxrow": 0,
		"allowdel": false,
		"fieldlist": [
			{
				"label": "Item",
				"field": "onsproduct_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "readonly"
			},
			{
				"label": "Active",
				"field": "onsbookingitem_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "readonly"
			},
			{
				"label": "Product Active",
				"field": "onsproduct_active",
				"subtotalid": "",
				"value": "",
				"input": "hidden",
				"mode": "readonly"
			},
			{
				"label": "Price/Unit",
				"field": "#onsproduct_price",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "QTY",
				"field": "onsbookingitem_deliverqty",
				"subtotalid": "deliverorderpcs",
				"value": "",
				"input": "integer",
				"mode": "readonly"
			},
			{
				"label": "Subtotal",
				"field": "onsbookingitem_deliverqty*onsproduct_price*onsbookingitem_active",
				"subtotalid": "deliverorderamount",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Notes",
				"field": "onsbookingitem_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly"
			},
		],
		"footerlist": [
			{
				"label": "Fees",
				"field": "onsbooking_fees",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Total",
				"field": "onsbooking_fees+deliverorderamount",
				"subtotalid": "",
				"destfield": "onsbooking_deliveramount",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
		]
	} */
];

const MODE_VIEW = 0;
const MODE_RELEASING = 1;
const MODE_RECEIVING = 2;
const MODE_FULL = 3;

const PACKAGESTATUS_TRANSIT = 2; // internal logistics
const PACKAGESTATUS_STORED = 3;

const PACKAGESTATUS_CUSTOMER = 5; // Released to customer/courier

const PackageeditorPage = ({location, mode, subformFields}) => {
	const [token, setToken] = useState("");
	const [userinfo, setUserinfo] = useState({});
	const [pagetitle, setPagetitle] = useState("");

	const [onsorderformfulfillmentid, setOnsorderformfulfillmentid] = useState(0);
	const [onsorderformid, setOnsorderformid] = useState(0);
	const [nnpickupid, setNnpickupid] = useState(0);

	const [nnpickupaliasid, setNnpickupaliasid] = useState(0);
	const [packagestatusid, setPackagestatusid] = useState(0);
	const [bookingstatusid, setBookingstatusid] = useState(0);

	const [locationid, setLocationid] = useState(0);
	const [locationname, setLocationname] = useState("");

	useEffect(()=> {
		// Handle Change on the parent side
		if (token.length > 0) {
			negosyonowAPI.getUserLocation(token, function(id, name) {
				setLocationid(id);
				setLocationname(name);
			});
		}
	}, [token]);

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var nnpickupalias_id = locationid;
		var packagestatus_id = PACKAGESTATUS_TRANSIT;
		//var pkid = 0;
		var result = "";
		//var customparam = {};
		const updatetimestr = formatTools.getDateStr();
		var customparam = JSON.parse(JSON.stringify(params));
		var tmpnotes = "";
		if (mode === MODE_VIEW) {
			callback({"status":"Error", "message":"No changes made"});
			return;
		}
/*
		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}
*/
		if (typeof mode !== "undefined") {
			if (mode === MODE_RECEIVING) {
				packagestatus_id = PACKAGESTATUS_STORED; // receiving
			} else if (mode === MODE_RELEASING) {
				packagestatus_id = PACKAGESTATUS_TRANSIT; // releasing
			} else if (params.hasOwnProperty("packagestatus_id") && mode === MODE_FULL) {
				if (params.packagestatus_id) {
					packagestatus_id = parseInt(params.packagestatus_id, 10);
				}
			}
		}
		if (nnpickupalias_id < 1) {
			// Not fixed location
			if (params.hasOwnProperty("nnpickupalias_id")) {
				if (params.nnpickupalias_id) {
					nnpickupalias_id = params.nnpickupalias_id;
				}
			}
		}

		if (params.hasOwnProperty("onsbooking_locationnotes")) {
			if (params.onsbooking_locationnotes) {
				tmpnotes = params.onsbooking_locationnotes;
			}
		}

		if (nnpickupalias_id < 1) {
			callback({"status":"Error", "message":"Invalid location"});
			return;
		}

		if (nnpickupalias_id === nnpickupaliasid ) {
			if (packagestatus_id === packagestatusid) {
				callback({"status":"Error", "message":"No change in location/status"});
				return;
			} else if (packagestatus_id === PACKAGESTATUS_STORED) {
				// Receive (after being released from same location)
				if (tmpnotes.length > 0) {
					tmpnotes = "; "+tmpnotes;
				}
				tmpnotes = "Backhaul"+tmpnotes;
				if (packagestatusid === PACKAGESTATUS_CUSTOMER) {
					// Needs to be rescheduled
					customparam.onsbookingstatus_id = 9;
					customparam.onsbooking_updated = updatetimestr;
					tmpnotes = "For Reschedule; "+tmpnotes;
					result = "For Reschedule";
				}
			}
		} // same location
		if (nnpickupalias_id === nnpickupid && onsorderformfulfillmentid === 3) {
			if (tmpnotes.length > 0) {
				tmpnotes = "; "+tmpnotes;
			}
			if (packagestatus_id === PACKAGESTATUS_STORED) {
				// Arrived at pick up destination
				customparam.onsbooking_takeout = 1;
				tmpnotes = "Ready for pick up"+tmpnotes;
				result = "Ready for pick up";
			} else if (packagestatus_id === PACKAGESTATUS_TRANSIT) {
				// Released to customer/courier
				packagestatus_id = PACKAGESTATUS_CUSTOMER;
				tmpnotes = "Released to Customer"+tmpnotes;
				result = "eleased to Customer";
			}
		}

		customparam.packagestatus_id = packagestatus_id;
		customparam.nnpickupalias_id = nnpickupalias_id;
		customparam.onsbooking_locationdate = updatetimestr;
		customparam.onsbooking_locationnotes = tmpnotes;

		//callback({"status":"Error", "message":"Debug"}); return;

		webappsAPI.savePayload(entity, customparam, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				handlePackageHistory(response.documentid, token, updatetimestr, nnpickupalias_id, packagestatus_id, tmpnotes, function(historyres) {
					triggerNotifications(response.documentid, onsorderformid, result, token, function() {
						callback(response);
					});
				}); // handlePackageHistory
			} else {
				callback(response);
			}
		});

	}

	function triggerNotifications(pkid, orderformid, result, token, callback)
	{
		if (result.length < 1) {
			callback();
			return;
		}
		// Use better SNS, no need to recompute
		negosyonowAPI.buildOrder(token, "order",{
			"notifysubject": result,
			"orderform": orderformid,
			"onsbooking": pkid,
			"action": "notifyorder"
		}).then(nnresponse => {
			callback();
		});
	}

	function handlePackageHistory(pkid, token, updatetimestr, nnpickup_id, packagestatus_id, notes, callback)
	{
		var action = "";
		if (packagestatus_id === PACKAGESTATUS_STORED) {
			action = "Received";
		} else if (packagestatus_id === PACKAGESTATUS_TRANSIT) {
			action = "Released";
		} else if (packagestatus_id === PACKAGESTATUS_CUSTOMER) {
			action = "Dispatched/Released to Customer";
		} else {
			action = "Others";
		}
		webappsAPI.saveRecord("ONSBOOKINGLOCATIONHISTORY", {
				"onsbooking_id": pkid,
				"onsbookinglocationhistory_datetime": updatetimestr,
				"nnpickup_id": nnpickup_id,
				"onsbookinglocationhistory_action": action,
				"onsbookinglocationhistory_notes": notes,
				"onsbookinglocationhistory_user": userinfo.name
			}, token).then(historyresponse => {
				callback({"status":"OK", "message":"Recorded"});
			}); // add history
	}

	function customSetFormData(newformdata)
	{
		var neworderformid = 0;
		var newpickupid = 0;
		var newonsorderformfulfillmentid = 0;
		var newpickupaliasid = 0;
		var newpackagestatusid = 0;
		var newbookingstatusid = 0;

		if (newformdata.hasOwnProperty("nnpickup_id")) {
			if (newformdata.nnpickup_id) {
				newpickupid = newformdata.nnpickup_id;
			}
		}

		if (newformdata.hasOwnProperty("onsorderformfulfillment_id")) {
			if (newformdata.onsorderformfulfillment_id) {
				newonsorderformfulfillmentid = newformdata.onsorderformfulfillment_id;
			}
		}
		if (newformdata.hasOwnProperty("nnpickupalias_id")) {
			if (newformdata.nnpickupalias_id) {
				newpickupaliasid = newformdata.nnpickupalias_id;
			}
		}
		if (newformdata.hasOwnProperty("onsorderform_id")) {
			if (newformdata.onsorderform_id) {
				neworderformid = newformdata.onsorderform_id;
			}
		}
		if (newformdata.hasOwnProperty("onsbookingstatus_id")) {
			if (newformdata.onsbookingstatus_id) {
				newbookingstatusid = newformdata.onsbookingstatus_id;
			}
		}
		if (newformdata.hasOwnProperty("packagestatus_id")) {
			if (newformdata.packagestatus_id) {
				newpackagestatusid = newformdata.packagestatus_id;
			}
		}

		setBookingstatusid(newbookingstatusid);
		setNnpickupid(newpickupid);
		setOnsorderformfulfillmentid(newonsorderformfulfillmentid);
		setOnsorderformid(neworderformid);
		setNnpickupaliasid(newpickupaliasid);
		setPackagestatusid(newpackagestatusid);
	}

	function customEditFieldInfo(inputlist, idx)
	{
		var tmpfield = {};
		if (mode === MODE_VIEW) {
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			if (inputlist[idx].field === "packagestatus_id") {
				tmpfield.label = "Package Status";
			} else if (inputlist[idx].field === "nnpickupalias_name") {
				tmpfield.label = "Package Location";
			}
			tmpfield.mode = "readonly";
			return tmpfield;
		}
		if (bookingstatusid === 2) {
			// dispatched
			if (inputlist[idx].field === "packagestatus_id") {
				if (typeof mode !== "undefined") {
					tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
					if (mode === MODE_FULL) {
						tmpfield.label = "New Status";
						tmpfield.options = [
							//{"value":2,"display":"In-Transit"}),
							{"value":3,"display":"Stored"},
							//{"value":4,"display":"Disposed"},
							//{"value":5,"display":"Released"}
						];
						if (onsorderformfulfillmentid !== 3 || nnpickupid !== nnpickupaliasid) {
							// Not pick up location or not pick up
							tmpfield.options.push({"value":2,"display":"In-Transit"});
						}
						if (onsorderformfulfillmentid !== 3) {
							tmpfield.options.push({"value":5,"display":"For Delivery"});
						} else if (nnpickupid === nnpickupaliasid) {
							tmpfield.options.push({"value":5,"display":"Release to Customer"});
						}
						tmpfield.mode = "required";
					} else if (mode === MODE_RECEIVING) {
						tmpfield.label = "New Status";
						tmpfield.options = [
							{"value":3,"display":"Received/Stored"},
						];
						tmpfield.value = 3;
						tmpfield.mode = "readonly";
						tmpfield.modified = true;
					} else if (mode === MODE_RELEASING) {
						tmpfield.label = "New Status";
						tmpfield.options = [
							{"value":2,"display":"Released/In-Transit"},
						];
						tmpfield.value = 2;
						tmpfield.mode = "readonly";
						tmpfield.modified = true;
					} else {
						tmpfield.mode = "readonly";
					}
					return tmpfield;
				}
			} else if (inputlist[idx].field === "nnpickupalias_name") {
				tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				if (locationid > 0) {
					// Co-located
					tmpfield.value = locationname;
					tmpfield.modified = true;
					tmpfield.mode = "readonly";
				} else if (tmpfield.modified === false) {
					console.log("Here");
					tmpfield.value = "-";
				}
				return tmpfield;
			}
		}
		return inputlist[idx];

	}

	function customPostLoadForm()
	{
		const title = mode === MODE_RECEIVING?"Receive":"Release";
		const actionmode = (mode === MODE_RECEIVING||mode === MODE_RELEASING?2:0)// 0 - nothing, 1 - edited, 2 - submitmode
		return {
			prompttitle: title+" Package",
			promptmessage: "",
			mode: locationid>0?actionmode:0
		};
	}
	return <Layout fullPath={location.pathname}
			newUserHandler={(newtoken, newuserinfo)=>{setToken(newtoken); setUserinfo(newuserinfo)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={false}
				allowdelete={false}
				pagetitle={pagetitle}
				mobilerowtitlefield={["onsorderform_code","onsorderform_datetime"]}
				userSearchParam={{"orderby":"onsorderform_datetime desc", "joincond": "ONSORDERFORM.onsorderform_id=ONSBOOKING.onsorderform_id and ONSBRANCH.onsbranch_id=ONSBOOKING.onsbranch_id and ONSORDERFORMFULFILLMENT.onsorderformfulfillment_id=ONSBOOKING.onsorderformfulfillment_id and PACKAGESTATUS.packagestatus_id=ONSBOOKING.packagestatus_id and NNPICKUPALIAS.nnpickupalias_id=ONSBOOKING.nnpickupalias_id and NNPICKUP.nnpickup_id=ONSBOOKING.nnpickup_id and ONSCUSTOMER.onscustomer_id=ONSBOOKING.onscustomer_id and 2=ONSBOOKING.onsbookingstatus_id"}}
				searchFields={searchFields}

				//userSearchFilter={[{"field":"packagestatus_id", "operation":"=", "value": 1}]}


				customSubmit={customSubmit}
				customSetFormData={customSetFormData}
				customEditFieldInfo={customEditFieldInfo}
				customPostLoadForm={customPostLoadForm}
				editFields={formFields}
				editSubFormFields={typeof subformFields !== "undefined" ? defaultSubformFields.concat(subformFields):defaultSubformFields}
				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default PackageeditorPage;
